import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectApp } from '../store/modules/app/slice';
import { ErrorToast } from '../components/ErrorToast';
import { lsI18NService } from '../service';
import { GraphQLCustomErrors, GraphQLHandledErrors } from '../constants/GraphQLErrors';
import { useTrackPayload } from './useTrackPayload';
import { Events } from '../enums/events';
import { trackErrorViewed } from '../service/segment/trackers';
import { ErrorMessage } from '../types/track';

export function useErrorHandler() {
  const { error: newError } = useSelector(selectApp);
  const [error, setError] = useState<string | null>(null);
  const { payloadBuilder } = useTrackPayload(Events.ErrorViewed);

  useEffect(() => {
    // To avoid unexpected re-renders on modal we should not update the error state here
    if (newError !== error) {
      setError(newError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newError]);

  const isErrorHandled = useMemo(() => (error ? GraphQLHandledErrors.includes(error) : true), [error]);

  useEffect(() => {
    if (isErrorHandled) return;

    const payload = payloadBuilder({
      errorMesage: ErrorMessage.UnhandledError,
      errorDetails: newError,
    });

    payload && trackErrorViewed(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorHandled]);

  const description = useMemo(() => GraphQLCustomErrors.find((e) => e.error === error)?.message, [error]);

  return {
    error,
    component: () =>
      !isErrorHandled && (
        <ErrorToast
          description={description || lsI18NService.t('genericError')}
          msToHide={5000}
          className="fixed w-[98%] mx-[1%] bottom-4 z-10"
        />
      ),
  };
}
