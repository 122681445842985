import { formatPlace } from '@lawnstarter/ls-react-common/helpers';
import { Place } from '@lawnstarter/ls-react-common/types';
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectApp } from '../../../store/modules/app/slice';
import { CAPTCHA_ACTIVATED, LOADING_DELAY_IN_MS, STATIC_MAPS_BASE_URL } from '../../../constants/general';
import { usePlaces } from '../../../hooks/usePlaces';
import { QueryString } from '../../../enums/storage';
import { InvalidPhoneError } from '../../../constants/GraphQLErrors';
import { selectContact } from '../../../store/modules/contact/slice';
import { selectProperty } from '../../../store/modules/property/slice';
import { useProspect } from './useProspect';
import { VITE_GOOGLE_API_KEY } from '../../../config/env';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackErrorViewed } from '../../../service/segment/trackers';
import { ErrorMessage } from '../../../types/track';

type ResolvedPlace = Place & { street_number?: string };

export interface DefaultValues {
  address1: string;
  address2: string;
  name: string;
  phone: string;
}

interface useContactInfoProps {
  setShowCalculationScreen: React.Dispatch<SetStateAction<boolean>>;
}

export const useContactInfo = ({ setShowCalculationScreen }: useContactInfoProps) => {
  const [searchParams] = useSearchParams();
  const nameParam = searchParams.get(QueryString.Name);
  const phoneParam = searchParams.get(QueryString.Phone);
  const addressParam = searchParams.get(QueryString.Address);
  const placeIdParam = searchParams.get(QueryString.GooglePlaceId);
  const segmentParam = searchParams.get(QueryString.SegmentId);
  const { payloadBuilder } = useTrackPayload(Events.ErrorViewed);
  const isPhoneErrorTracked = useRef(false);
  const { turnstileToken } = useSelector(selectApp);
  const contact = useSelector(selectContact);
  const property = useSelector(selectProperty);
  const address1 =
    property.address1 && property.city && property.state && property.zip
      ? `${property.address1}, ${property.city}, ${property.state} ${property.zip}`
      : '';

  const { control, handleSubmit, setValue, getValues, formState, setError, trigger, clearErrors } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      address1,
      address2: property.address2 || '',
      name: nameParam || contact.name || '',
      phone: phoneParam || contact.phone || '',
    } as DefaultValues,
  });

  const { handleProspect, data, isSuccess, isLoading, isError, error, isUninitialized, reset } = useProspect();
  const [place, setPlace] = useState<ResolvedPlace>();
  const [isAutofill, setIsAutofill] = useState(false);
  const [delayIsOver, setDelayIsOver] = useState(false);
  const { prediction, getPlaceByInput, getPlaceById, isReady } = usePlaces();

  const resolveZip = (place?: ResolvedPlace) => (place?.street_number ? place.zip : '');

  const staticMapUrl = useMemo(() => {
    const mapParams = {
      center: addressParam ?? `${place?.street},${place?.city},${place?.state},${place?.zip}`,
      zoom: '16',
      size: '600x300',
      maptype: 'satellite',
      key: VITE_GOOGLE_API_KEY,
    };

    return `${STATIC_MAPS_BASE_URL}?${new URLSearchParams(mapParams).toString()}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [place]);

  const triggerAddressCompliance = (place: ResolvedPlace) => {
    const isAddressCompliant =
      place.street && place.city && place.state && place.zip && place.country && place.street_number;

    if (!isAddressCompliant) {
      const payload = payloadBuilder({
        errorMesage: ErrorMessage.InvalidAddress,
        errorDetails: place,
      });

      payload && trackErrorViewed(payload);
    }
  };

  // Handle validation errors
  useEffect(() => {
    const { message, error: phoneError } = InvalidPhoneError;
    const isInvalidPhoneNumber = error?.message?.includes(phoneError);
    if (isInvalidPhoneNumber) {
      const payload = payloadBuilder({ errorMesage: ErrorMessage.InvalidPhoneNumber });

      if (!isPhoneErrorTracked.current && payload) {
        trackErrorViewed(payload);
        isPhoneErrorTracked.current = true;
      }

      setError('phone', { type: 'error', message });
      setIsAutofill(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const { address1, city, state, zip, country, number } = property;
    const isPropertyAvailable = address1 && city && state && zip && country && number;

    if (isPropertyAvailable) {
      setPlace({ street: address1, city, state, zip, country, street_number: number });
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * AutoFill process: if the user comes from anywhere contianing name, phone, and address as queryString the handle will be dispatched
   */
  useEffect(() => {
    if (nameParam && phoneParam && addressParam) {
      setIsAutofill(true);
      setShowCalculationScreen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAutofillReady = CAPTCHA_ACTIVATED
      ? isAutofill && isReady && isUninitialized && turnstileToken
      : isAutofill && isReady && isUninitialized;

    if (!isAutofillReady) return;

    if (placeIdParam) {
      getPlaceById(placeIdParam);
    } else if (addressParam) {
      getPlaceByInput(addressParam);
    }
  }, [addressParam, getPlaceById, getPlaceByInput, isAutofill, isReady, isUninitialized, placeIdParam, turnstileToken]);

  useEffect(() => {
    if (isAutofill && prediction) {
      setShowCalculationScreen(true);
      setTimeout(() => setDelayIsOver(true), LOADING_DELAY_IN_MS);

      const predictedPlace = formatPlace({ place: prediction }) as ResolvedPlace;

      setPlace(place);

      triggerAddressCompliance(predictedPlace);

      handleProspect({
        name: nameParam || '',
        phone: phoneParam || '',
        segmentAnonymousId: segmentParam,
        address1: predictedPlace.street,
        address2: predictedPlace.complement || '',
        city: predictedPlace.city,
        state: predictedPlace.state,
        zip: resolveZip(predictedPlace),
        country: predictedPlace.country,
        street_number: predictedPlace.street_number,
      });

      setIsAutofill(false);
      setPlace({ ...place, street_number: '' } as ResolvedPlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutofill, prediction]);

  /**
   * When the user submit the form
   */
  const onSubmit = useCallback(async () => {
    return new Promise((resolve, reject) => {
      handleSubmit(
        (formInput) => {
          setTimeout(() => setDelayIsOver(true), LOADING_DELAY_IN_MS);

          if (!isAutofill && place?.street_number) {
            resolve(handleProspect({ ...place, ...formInput }));
          }
        },
        () => {
          reject('Invalid data');
        },
      )();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, place, isAutofill]);

  /**
   * When the user select an address from input
   */
  const onSetAddress = (params: { place: Place }) => setPlace(params.place);

  /**
   * It ensures address can't be an empty string
   */
  const setAddressInput = useCallback(
    (_text: string) => {
      const address = getValues('address1');
      if (!address) {
        setValue('address1', '');
      }
    },
    [getValues, setValue],
  );

  return {
    delayIsOver,
    onSubmit,
    onSetAddress,
    setAddressInput,
    place,
    staticMapUrl,
    form: {
      control,
      isValid: formState.isValid,
      trigger,
      clearErrors,
    },
    mutation: {
      data,
      isLoading,
      isSuccess,
      isError,
      error,
      isUninitialized,
      isAutofill,
      reset,
    },
  };
};
